import React from 'react'
import Layout from '../components/layout'
import GoDevelopment from '../components/categories/go-development'
import Seo from '../components/seo'

const GoDevelopmentPage = ({ location }) => {
  return (
    <Layout location={location}>
      <GoDevelopment />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Go Development Freelancers | Codestaff'
  const description =
    'Hire the best Go Development freelancers at Codestaff. Get the top 1% of Go Development professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default GoDevelopmentPage
